.leadStatusError{
    color: red;
}

.recordingsModal {
    width: 35% !important;
}

.recordingsModalContainer{
    padding: 5px !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}


.recording-info, .recording-type {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
  }
  
  .recording-time {
    font-size: 14px;
    color: #555;
    font-weight: 500;
  }

  .CallLogsBtn {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    padding: 5px 20px;
    background-color: #f8a504;
    margin-left: 10px !important;
    height: 40px !important;
    color: #fff !important;
}

.CallLogsBtn:hover {
    
    background-color: #f8a70477;
    color: #000 !important;
}

.callProgressModal .ant-modal-content {
    border-radius: 20px;
    height: 450px;
    box-shadow: -4px 4px 14px -3px #2f2d2a;
}


.contactLeadContainer {
    text-align: center;
    margin: 30px 0px 30px 0px;
    padding-top:20px;
}

.contactLeadContainer .callContent {
    margin: 20px;
    padding-top: 20px;
}

.projectStartLabel {
    margin-right: 12px;
    margin-bottom: 0px !important;
    margin-left: 4px;
    margin-top: 10px;
}