/* .nav-logo{
    height:50px;
    margin-top: 5px;
    margin-left:40px;
} */
 .parent{
    position: relative;
 }

.search-layout{
    position:relative;
}
.notification-badge {
    position: absolute;
    top: 4px;
    right: 23px;
    background-color: red;
    color: white;
    border-radius: 40%;
    padding: 0px 6px;
    font-size: 11px;
}

/* .search-field{
    border: none;
    border-radius: 8px;
    width:300px;
    margin-left:100px;
    margin-top: 5px;
    padding: 8px;
    background-color: rgb(240, 238, 238);
} */
/* .search-icon{
    margin-left: -30px;
} */
.data-result{
    position: absolute;
    margin-left: 250px;
    height: 100px;
    width: 300px;

}
.off-icon-layout{
    display: flex;
}
.off-icon{
    margin-top: 3px;
    margin-right: 10px;
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Figtree', sans-serif;
}

body {
/*     min-height: 100vh; */
/*     background: #3A1C71; */
/*     background: linear-gradient(45deg, #3A1C71, #D76D77, #FFAF7B); */
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* menuDropdown toggle */

.menuDropdown-toggle {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuDropdown-toggle::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 4px;
    background: #000;
    box-shadow: 0 8px 0 #000,
                0 -8px 0 #000;
}

/* profile menuDropdown */

.profile {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    text-align: end;
}

.profile h3 {
    text-align: end;
    line-height: 1;
    margin-bottom: 4px;
    font-weight: 600;
}

.profile p {
    line-height: 1;
    font-size: 14px;
    opacity: .6;
}

.profile .img-box {
    position: relative;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid grey;
}

.profile .img-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* menuDropdown (the right one) */

.menuDropdown {
    position: absolute;
    top: calc(100% + 24px);
    right: 16px;
    width: 200px;
    min-height: 100px;
    background: #fff;
    box-shadow: 0 10px 20px rgb(0, 0, 0);
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: 300ms;
    border-radius: 12px;
}

.menuDropdown::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 14px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
    z-index: -1;
}

.menuDropdown.active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

/* menuDropdown links */

.menuDropdown ul {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background: #fff;
    padding: 0% !important;
    border-radius: 12px;
}

.menuDropdown ul li {
    align-items: center;
    list-style: none;
    padding: 15px 20px;

}

.menuDropdown ul li:hover {
    background: #eee;
    align-items: center;

}

.menuDropdown ul li a {
    text-decoration: none;
    color: #000;
    display: flex;
    align-items: center;
    gap: 6px;
}
