.contentHead{
    height: 62px;
    display: flex;
    flex-direction: row;
   }
   .contentHead .rounded {
       padding: 5px 10px;
       background-color: rgb(255,255,255);
       border: 1px solid #eee ;
       margin-top: 20px;
       margin-left: 30px;
   }
   /* .date-selector{
       width: 250px;
       margin-top: 20px;
       background-color: rgb(255,255,255);
       border: 1px solid #eee ;
       margin-left: 895px;
       font-weight: bold;
   } */
   /* .Filter{
       padding: 8px;
       background-color: rgb(255,255,255);
       border: 1px solid #eee ;
       margin-top: 20px;
       margin-left: 10px;
       border-radius: 10px;
   } */
   .calendar{
       position: relative;
   }
   
   
   .rdrDefinedRangesWrapper{
   width: 202px;
   }
   .rdrCalendarWrapper{
       font-size: 11px;
   }
   /* .crmCardsRow1,.crmCardsRow2{
       display: flex;
       flex-direction: row;
       justify-content: space-between;
   } */
   
   
   
   
   .LeadsLayout{
       display: flex;
       flex-direction: row;
       padding: 30px;
       justify-content: space-between;
   }
   
   
   
   .headText{
       font-weight: bold;
       font-size: 18px;
       margin-left: 20px;
   }
   .headTitle{
       padding-top: 20px;
   }
   
   .chartHeadTitle{
     height: 40px;
     padding: 5px;
   }
   .chartHeadTitle span{
     font-weight: bold;
     font-size: 20px;
     padding-left: 20px;
   }
   
   .square{
       height: 10px;
       width: 10px;
   }
   
   .dashboardHeader {
    justify-content: flex-end !important;
   }
   
   /* ======= Dashboard card styles ======= */
   .seDashboardWrapper {
       margin: 20px;
   
       .smCard {
           border-radius: 7px;
       }
   
       .col:nth-child(2) .smCard {
           background: linear-gradient(130deg, rgb(241, 69, 106) 0%, rgb(95, 125, 245) 100%);
           color: #fff;
       }
   
       .col:nth-child(4) .smCard {
           background: linear-gradient(130deg, rgb(3,67,108) 0%, rgb(118, 175, 210) 100%);
           color: #fff;
       }
   
       .col:nth-child(1) .smCard {
           background: linear-gradient(130deg, rgb(239, 70, 107) 0%, rgb(245, 153, 67) 100%);
           color: #fff;
       }
   
       .col:nth-child(3) .smCard {
           background: linear-gradient(130deg, #f96e6e 0%, rgba(247, 159, 18, 0.945) 100%);
           color: #fff;
       }

       .col:nth-child(5) .smCard {
            background: linear-gradient(130deg, #f99f6e 0%, rgba(197, 9, 40, 0.945) 100%);
            color: #fff;
        }
   
       .cardItem {
           padding: 24px 10px;
   
           .cardHead {
               display: flex;
               flex-direction: row;
               justify-content: space-evenly;
               border-bottom: 1px solid #ddc8fc;
               padding-bottom: 24px;
           }
   
           .cardTopTitle {
               font-size: 20px;
               font-weight: 500;
               text-align: center;
           }
   
           .leadValue {
               font-weight: 500;
               font-size: 50px;
               line-height: 1.1;
           }
   
           .cardStatusInfo {
               display: flex;
               justify-content: space-around;
               text-align: center;
   
               div {
                   display: flex;
                   flex-direction: column;
                   justify-content: space-around;
                   padding-top: 10px;
   
                   span:first-child {
                       font-size: 16px;
                       font-weight: 500;
                       height: 34px;
                   }
               }

               div {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-top: 10px;

                span:last-child {
                    font-size: 10px;
                }
            }
           }
       }
   }
   
   /* ======= Dashboard card styles end ======= */